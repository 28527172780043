.notfoundpage {
    text-align: center;
    
    .emoji {
        font-size: 1.1em;
    }

    @media screen and (min-width: 768px) {
        font-size: 20px;
    }
}
