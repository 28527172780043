.dashboardpage {
    position: relative;
    .analytics-zone {
      display: flex;
      gap: 15px;
      align-items: center;
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(1);
      z-index: 1;
      justify-content: center;

      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        .qr-zone {
          margin-left: 50%;
          margin-right: 50%;
        }

        .mood-count-zone {
          order: 1;
          .emotion-icon {
            width: 45px;
            height: 45px;
          }
        }
      }

      .qr-zone::before,
      .mood-count-zone {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 40.63%, rgba(255, 255, 255, 0) 100%);
        backdrop-filter: blur(20px) brightness(0.6);
        border-radius: 20px;
        @media screen and (min-width: 1024px) {
          border-radius: 27px;
        }
      }

      @media screen and (min-width: 768px) {
        gap: 30px;
      }

      @media screen and (min-width: 1024px) {
        gap: 40px;
      }
      .qr-zone {
        position: relative;
        padding: 30px;
        &:hover{
          opacity: 1;
        }
        @media screen and (min-width: 1024px) {
          padding: 2.5vw;
        }
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
        > h1 {
          margin: 0;
          > span {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 18px;
            font-weight: normal;
            line-height: 1;
            position: absolute;
            width: 100%;
            display: block;
            @media screen and (min-width: 1024px) {
              font-size: 2vw;
            }

            &:nth-child(1) {
              top: 12px;
              left: 0;
            }
            &:nth-child(2) {
              right: -100%;
              top: 0;
              transform: rotate(90deg) translateY(calc(100% - 1vw));
              transform-origin: top left;
            }
            &:nth-child(3) {
              top: calc(100% - 12px);
              left: 0;
              transform: translateY(-100%);
            }
            &:nth-child(4) {
              left: -100%;
              top: 0;
              transform: rotate(270deg) translateY(calc(100% - 1vw));
              transform-origin: top right;
            }
          }
        }
        > img,
        svg {
          width: 90px;
          height: 90px;
          display: block;
          padding: 10px;
          @media screen and (min-width: 1024px) {
            width: 200px;
            height: 200px;
            padding: 15px;
          }
          @media screen and (min-width: 1024px) {
            width: 10vw;
            height: 10vw;
            padding: 0.75vw;
          }
        }
      }

      .mood-count-zone {
        padding: 15px 10px;
        text-align: center;
        font-size: 22px;
        font-weight: 700;

        @media screen and (min-width: 768px) {
          padding: 30px 30px 20px;
          .emotion-icon {
            width: 5.25vw;
            height: 5.25vw;
            margin-bottom: 1vw;
          }
        }

        @media screen and (min-width: 1024px) {
          padding: 2vw 2vw 1vw;
          font-size: 2.5vw;
          .emotion-icon {
            width: 6.25vw;
            height: 6.25vw;
            margin-bottom: 1.3vw;
          }
        }

        .emotion-icon {
          position: relative;
          transform: unset;
          margin-bottom: 7px;
        }
      }
    }

    .emotion-zone {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      // .floating {
      //   display: block;
      //   position: relative;
      //   width: 39px;
      //   animation-fill-mode: backwards;
      //   transition: all ease 2s;
      //   @media screen and (min-width: 768px) {
      //     width: 5vw;
      //   }
      // }
      .rfm-marquee-container {
        padding: 2vh 0;
        backdrop-filter: blur(20px) brightness(0.6);

        z-index: 1;
        .rfm-child {
          padding: 0 5vw;
          span {
            font-size: 3vh;
            line-height: 1;
            display: block;
          }
        }
      }
    }
  }
