@import url("https://fonts.googleapis.com/css2?family=Itim&family=Slackside+One&display=swap");

.font-vietnamese {
    font-family: "Itim", cursive !important;
    font-style: italic;
}

.drawdashboardpage {
    .container {
        overflow-x: hidden;
    }
    .qr-zone {
        width: 200px;
        height: 200px;
        font-size: 26px;
        box-sizing: border-box;
        border: 5px solid #f4a223;
        text-align: center;
        padding: 10px;
        color: #a50c12;
        display: flex;
        gap: 7px;
        align-items: center;
        flex-direction: column;
        margin-top: 40px;
        margin-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
        background: #ffffff url(./../data/img/draw/card/img_bg_qr.png) center no-repeat;
        background-size: 94%;
        color: #a50c12;

        &:hover {
            opacity: 1;
            background-color: #f0ead2;
        }

        * {
            font-family: "Slackside One", "cursive";
        }

        > p {
            margin: 0;
            line-height: 1;
            margin-top: -5.5%;
        }

        > svg {
            max-width: calc(100% - 20px);
            height: auto;
        }

        @media screen and (min-width: 1024px) {
            width: 14vw;
            height: 14vw;
            gap: 0.520833vw;
            font-size: 2.0833vw;
            border: 0.520833vw solid #f4a223;
            padding: 0.6vw;
            position: absolute;
            left: 4.5vw;
            bottom: 10vw;
            transform: rotate(-5.53deg);
            margin-top: 0;
        }
    }
}
