.draw-frame {
    border: 6px solid #fff;
    height: calc((100vh - 12px) / 4);
    width: calc((100vh - 12px) / 4);

    @media screen and (min-width: 1024px) {
        border: 8px solid #ffffff;
        height: calc((100vh - 16px) / 4);
        width: calc((100vh - 16px) / 4);
    }
    .draw {
        background: #fff url(../data/img/draw/template.jpg) center no-repeat;
        background-size: contain;
        border: 2px solid #a50c12;

        @media screen and (min-width: 1024px) {
            border: 5px solid #a50c12;
        }

        > img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}
