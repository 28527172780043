.main {
  section {
    padding: 25px 0 0;
    box-sizing: border-box;
    @media screen and (min-width: 768px) {
      padding: 50px 0 20px;
    }
  }

  h1 {
    &.main-title {
      text-align: center;
      font-size: 36px;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 25px;

      @media screen and (min-width: 768px) {
        font-size: 42px;
        margin-bottom: 30px;
      }

      @media screen and (min-width: 1024px) {
        font-size: 54px;
        margin-bottom: 40px;
      }
    }
  }

  .container {
    max-width: 800px;
    padding: 0 25px;
    margin: 0 auto;
  }
}

.emotion-icon {
  width: 40px;
  height: 40px;
  transition:
    all ease-out 2.5s,
    opacity ease 1.5s;
  position: absolute;
  transform: translate(-50%, -50%); // This will center the element
  opacity: 1;

  @media screen and (min-width: 768px) {
    width: 3.25vw;
    height: 3.25vw;
  }

  &.message-box {
    > span {
      display: none;
    }

    @media screen and (min-width: 1024px) {
      width: auto;
      animation: none !important;
      > span {
        display: block;
        background: #fff;
        color: #272727;
        font-size: 14px;
        padding: 6px 10px;
        border-radius: 14px;
      }
    }
  }

  &.fly-from-center {
    left: 50% !important;
    top: 50% !important;
    opacity: 0;
    &.new-icon {
      filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.75));
    }
  }

  &[mood-type~="happy"] {
    background: transparent url(data/img/icon_happy.png) center no-repeat;
    background-size: contain;
  }
  &[mood-type~="energetic"] {
    background: transparent url(data/img/icon_energetic.png) center no-repeat;
    background-size: contain;
  }
  &[mood-type~="sad"] {
    background: transparent url(data/img/icon_sad.png) center no-repeat;
    background-size: contain;
  }
  &[mood-type~="swing"] {
    background: transparent url(data/img/icon_swing.png) center no-repeat;
    background-size: contain;
  }
  &[mood-type~="seasonal"] {
    &:nth-child(n) {
      background: transparent url(data/img/icon_happy.png) center no-repeat;
      background-size: contain;
    }
    &:nth-child(2n) {
      background: transparent url(data/img/icon_energetic.png) center no-repeat;
      background-size: contain;
    }
  }

  &.theme-vnwomenday,
  &.theme-womenday,
  &.theme-valentine {
    $totalImage: 6;
    @for $i from 1 through $totalImage {
      &:nth-of-type(#{$totalImage}n + #{$i}) {
        background-image: url("data/img/seasonal/vnwomenday/icon_#{$i}.png");
        background-size: contain;
      }
    }
  }

  &.theme-halloween {
    &[mood-type~="seasonal"] {
      $totalImage: 12;
      @for $i from 1 through $totalImage {
        &:nth-of-type(#{$totalImage}n + #{$i}) {
          background-image: url("data/img/seasonal/halloween/icon_#{$i}.png");
          background-size: contain;
        }
      }
    }
  }

  &.theme-thanksgiving {
    &[mood-type~="seasonal"] {
      $totalImage: 12;
      @for $i from 1 through $totalImage {
        &:nth-of-type(#{$totalImage}n + #{$i}) {
          transform: scale(2);
          background-image: url("data/img/seasonal/thanksgiving/icon_#{$i}.png");
          background-size: contain;
        }
      }
    }

    @media screen and (min-width: 768px) {
      width: 5vw;
      height: 5vw;
    }
  }
}


