.drawpage {
    .container {
        padding: 0;
        user-select: none !important;
        section {
            padding-top: 10px;
            h1.main-title {
                margin-bottom: 10px;
            }
        }
    }

    h1.main-title {
        @media screen and (max-width: 500px) {
            font-size: 30px;
        }
    }

    .drawing-container {
        margin: 0 auto;
        width: 420px;
        height: 420px;
        max-width: calc(100vw);
        max-height: calc(100vw);
    }

    .action-container {
        padding: 0 20px;
        max-width: 420px;
        margin-left: auto;
        margin-right: auto;

        .action-list {
            display: flex;
            gap: 15px;
            padding: 15px 0;
            justify-content: center;
            .btn {
                font-size: 14px;
                padding: 6px 12px;
            }
        }

        .pen-options {
            padding: 10px 0;
            display: flex;
            gap: 10px;
            font-size: 14px;
            .stroke-color {
                display: flex;
                align-items: center;
                > label {
                    display: inline-flex;
                    align-items: center;
                    &::after {
                        content: "";
                        display: inline-block;
                        width: 25px;
                        height: 25px;
                        border: 2px solid #fff;
                        margin-left: 8px;
                        border-radius: 50%;
                        background-color: var(--preview-color);
                    }
                }
                > input {
                    width: 0;
                    height: 0;
                    opacity: 0;
                    z-index: -100;
                    pointer-events: none;
                }
            }

            .stroke-width {
                flex: 1;
                .range-slider {
                    display: flex;
                    align-items: center;
                    .range {
                        margin-left: 10px;
                        margin-right: 5px;
                        width: 100px;
                    }
                    .value {
                        display: inline-flex;
                        width: 25px;
                        height: 25px;
                        background-color: #00000038;
                        border-radius: 50px;
                        align-items: center;
                        justify-content: center;
                        line-height: 1;
                        font-size: 12px;
                    }
                }
            }

            .stroke-eraser {
                label {
                    input[type="checkbox"] {
                        display: none;
                    }
                    span {
                        display: block;
                        background: #ffffff;
                        padding: 7px;
                        border-radius: 50px;
                        opacity: 0.6;
                        > svg {
                            display: block;
                            max-width: 25px;
                        }
                        &.active {
                            opacity: 1;
                            background: #d1ea2c;
                        }
                    }
                }
            }
        }

        .submit-zone {
            padding: 15px 0 0;
            display: flex;
            gap: 15px;
            .btn {
                display: block;
                width: 100%;
                max-width: 500px;
                margin: 0 auto;
            }
        }
    }

    .action-list {
        display: flex;
        gap: 15px;
        padding: 15px 0;
        justify-content: center;
        .btn {
            font-size: 14px;
            padding: 6px 12px;
        }
    }

    .pen-options {
        > label {
            display: inline-flex;
            align-items: center;
            gap: 8px;
            &::after {
                content: "";
                display: inline-block;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background-color: var(--preview-color);
            }
        }
        > input {
            width: 0;
            height: 0;
            opacity: 0;
            z-index: -100;
            pointer-events: none;
        }
    }

    .submit-zone {
        padding: 15px 0 0;
        .btn {
            display: block;
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
        }
    }
}
