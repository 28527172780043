body {
  margin: 0;
  font-family: "Snowflake Sans";
  font-weight: 400;
  font-style: normal;
  line-height: 1.4;
  color: #ffffff;
  background-color: #a67ec5;
  * {
    box-sizing: border-box;
  }
  div,
  image,
  a,
  input,
  select,
  button,
  textarea {
    font-family: "Snowflake Sans";
    font-weight: 400;
    font-style: normal;
    // transition: all ease 0.35s;
  }

  textarea {
    line-height: 1.5;
  }
  strong,
  b {
    font-weight: 700;
  }
  img {
    max-width: 100%;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a {
    font-weight: bold;
    text-decoration: none;
    color: #fffae3;
    transition: all ease 0.35s;
    &:hover {
      opacity: 0.7;
    }
  }
}

//gradient background
.orb-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.9);
  pointer-events: none;
  z-index: -2;
  background-image: url(./data/img/img_bg_default.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  &.mood-happy {
    background-image: url(./data/img/img_bg_happy.jpg);
  }
  &.mood-energetic {
    background-image: url(./data/img/img_bg_energetic.jpg);
  }
  &.mood-sad {
    background-image: url(./data/img/img_bg_sad.jpg);
  }
  &.mood-swing {
    background-image: url(./data/img/img_bg_swing.jpg);
  }
  &.christmas {
    background-image: url(./data/img/img_bg_xmas.jpg);
  }
}

//select
.input-wrap {
  border-radius: 50px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 40.63%, rgba(255, 255, 255, 0) 100%);
  &.active {
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    select {
      font-weight: 700;
    }
  }
  select {
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-position: calc(100% - 10px) 12px;
    background-repeat: no-repeat;
  }
  select,
  input {
    display: inline-block;
    appearance: none;
    font-size: 20px;
    line-height: 1;
    border: 0;
    text-align: center;
    color: #fff;
    width: 100%;
    min-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 15px 35px 15px 20px;
    border-radius: inherit;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    option {
      color: #272727;
    }

    &::placeholder {
      color: #ffe667;
      opacity: 1; /* Firefox */
      font-size: 14px;
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: #ffe667;
      font-size: 14px;
    }
  }
}

//btn
.btn {
  cursor: pointer;
  position: relative;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  background-color: transparent;
  appearance: none;
  padding: 10px 18px;
  border: 2px solid #ffffff;
  border-radius: 20px;
  transition: all ease-in 0.25s;

  &:disabled {
    opacity: 0.25;
    pointer-events: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &.submit-btn {
    font-size: 32px;
    padding: 5px 10px;
    border-radius: 0;
    border: none;
    &::after {
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none"><path d="M21 1L10 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M21 1L14 21L10 12L1 8L21 1Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      display: inline-block;
      margin-left: 8px; /* Optional: add some spacing between the SVG and the text */
      transition: transform 500ms;
    }

    &:hover,
    &:active {
      opacity: 1;
      &::after {
        transform: translate(5px, -8px);
      }
    }
  }

  &.btn-secondary {
    background-color: rgb(31, 60, 189);
    border-color: rgb(31, 60, 189);
  }
  &.btn-danger {
    background-color: rgb(189, 31, 31);
    border-color: rgb(189, 31, 31);
  }
  &.btn-info {
    background-color: rgb(22, 157, 7);
    border-color: rgb(22, 157, 7);
  }
  &.btn-small {
    font-size: 12px;
    padding: 6px 10px;
  }
}

.btn-group {
  .btn {
    ~ .btn {
      margin-top: 10px;
    }
  }
}

// .orb-bg {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   filter: brightness(0.9);
//   pointer-events: none;
//   z-index: -2;
//   background-color: #9174db;
//   transition: background-color cubic-bezier(0.08, 0.82, 0.74, 0.29) 2s;
//   .ball {
//     --delay: 0s;
//     --size: 0.4;
//     --speed: 25s;
//     @media (max-width: 767px) {
//       &:first-child {
//         width: 65%;
//       }
//       &:nth-child(2) {
//         width: 35%;
//       }
//       &:nth-child(3) {
//         width: 70%;
//       }
//     }
//     aspect-ratio: 1;
//     width: calc(150% * var(--size));
//     background: linear-gradient(200deg, #ff3333 50%, #f7c02b 50%);
//     filter: blur(10vw);
//     border-radius: 50%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     animation: loop var(--speed) infinite linear;
//     animation-delay: var(--delay);
//     transform-origin: 50% 50%;
//     opacity: 0.8;
//   }
//   &.mood-happy,
//   &.mood-energetic {
//   }
//   &.mood-sad,
//   &.mood-swing {
//     background-color: #140214;
//     .ball {
//       &:nth-child(1) {
//         background: linear-gradient(180deg, #330b89 0%, #ff41f2 100%);
//         filter: blur(200px);
//       }
//       &:nth-child(2) {
//         background: #ff847a;
//       }
//       &:nth-child(3){
//         background: #2D1363;
//       }
//     }
//   }
// }

//ANIMATIONS
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

footer {
  font-size: 20px;
  padding: 20px 0 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: #fff;
  img {
    width: 30px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 30px;

    img {
      width: 40px;
    }
  }

  @media screen and (min-width: 1200px) {
    // font-size: 1.875vw;
    // img {
    //   width: 2.4vw;
    // }
    font-size: 1.5vw;
    img {
      width: 2vw;
    }
  }
}

//FONT
@font-face {
  font-family: "Snowflake Sans";
  src:
    url("data/font/SnowflakeSans-Book.woff2") format("woff2"),
    url("data/font/SnowflakeSans-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Snowflake Sans";
  src:
    url("data/font/SnowflakeSans-Bold.woff2") format("woff2"),
    url("data/font/SnowflakeSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* KEYFRAMES */
@keyframes zoomIn {
  0% {
    transform: scale(0.7) translateY(0%);
  }
  25% {
    transform: scale(0.9) translateY(-30%);
  }
  50% {
    transform: scale(1.5) translateY(0%);
  }
  75% {
    transform: scale(0.9) translateY(30%);
  }
  100% {
    transform: scale(0.7) translateY(0%);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1) translateY(0%);
  }
  25% {
    transform: scale(0.8) translateY(30%);
  }
  50% {
    transform: scale(0.45) translateY(0%);
  }
  75% {
    transform: scale(0.8) translateY(-30%);
  }
  100% {
    transform: scale(1) translateY(0%);
  }
}

@keyframes zoomInStatic {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes zoomOutStatic {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loop {
  0% {
    transform: translate3D(0%, 51%, 0) rotate(0deg);
  }
  5% {
    transform: translate3D(8%, 31%, 0) rotate(18deg);
  }
  10% {
    transform: translate3D(22%, 13%, 0) rotate(36deg);
  }
  15% {
    transform: translate3D(40%, 2%, 0) rotate(54deg);
  }
  20% {
    transform: translate3D(46%, 21%, 0) rotate(72deg);
  }
  25% {
    transform: translate3D(50%, 47%, 0) rotate(90deg);
  }
  30% {
    transform: translate3D(53%, 80%, 0) rotate(108deg);
  }
  35% {
    transform: translate3D(59%, 98%, 0) rotate(125deg);
  }
  40% {
    transform: translate3D(84%, 89%, 0) rotate(144deg);
  }
  45% {
    transform: translate3D(92%, 68%, 0) rotate(162deg);
  }
  50% {
    transform: translate3D(99%, 47%, 0) rotate(180deg);
  }
  55% {
    transform: translate3D(97%, 21%, 0) rotate(198deg);
  }
  60% {
    transform: translate3D(80%, 7%, 0) rotate(216deg);
  }
  65% {
    transform: translate3D(68%, 25%, 0) rotate(234deg);
  }
  70% {
    transform: translate3D(59%, 41%, 0) rotate(251deg);
  }
  75% {
    transform: translate3D(50%, 63%, 0) rotate(270deg);
  }
  80% {
    transform: translate3D(38%, 78%, 0) rotate(288deg);
  }
  85% {
    transform: translate3D(21%, 92%, 0) rotate(306deg);
  }
  90% {
    transform: translate3D(3%, 79%, 0) rotate(324deg);
  }
  100% {
    transform: translate3D(0%, 51%, 0) rotate(360deg);
  }
}

// table
.table-wrapper {
  overflow-x: auto;
  width: 100%;
  .table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: #fff;
    color: #222;
    border: 3px solid #3c3e40;

    thead {
      background-color: #ffdd76;

      th {
        padding: 10px 15px;
        border: 2px solid #3c3e40;
        text-align: center;
        font-weight: bold;
        @media (max-width: 550px) {
          font-size: 12px;
          padding: 10px 5px;
        }
      }
    }

    tbody {
      tr {
        transition: background-color 0.2s;

        &:nth-child(odd) {
          background-color: #fbfbfb;
        }

        &:hover {
          background-color: #faeeca;
        }

        td {
          padding: 10px 15px;
          border: 2px solid #3c3e40;
          font-size: 16px;

          @media (max-width: 550px) {
            font-size: 12px;
            padding: 10px 5px;
            text-align: center;
          }

          small {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 80%;
            display: inline-block;
            margin-left: 10px;
            background: #333;
            color: #ffffff;
            padding: 7px 8px 5px;
            border-radius: 10px;
            line-height: 1;
            @media (max-width: 550px) {
              display: block;
              margin-left: 0;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}

//form and popup
.form-popup {
  background: #f7841e url(./data/img/draw/img_main_bg.jpg) center no-repeat;
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-101vw);
  transition: all ease 0.5s;
  &.show {
    transform: translateX(0);
  }

  .form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 25px;
  }

  .form-header {
    margin-bottom: 15px;
    text-align: right;
    > span {
      display: inline-block;
      margin-left: auto;
      border-radius: 12px;
      border: 2px solid #fff;
      padding: 8px 10px;
      line-height: 1;
    }
  }
}

.form-group {
  label {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    margin-bottom: 5px;
    small {
      display: inline-block;
      margin-left: auto;
      margin-right: 10px;
      font-weight: normal;
      font-size: 70%;
      line-height: 2;
    }
  }

  input,
  textarea,
  select {
    padding: 15px 20px;
    appearance: none;
    font-size: 16px;
    border-radius: 25px;
    background-color: #ffffff;
    width: 100%;
    border: 1px solid #fff;
    font-family: Arial, Helvetica, sans-serif;
    &:focus {
      outline: none;
    }
  }

  textarea {
    resize: none;
  }

  ~ .form-group {
    margin-top: 25px;
  }

  .btn {
    display: block;
    width: 100%;
  }
}

.config-block {
  padding: 20px;
  border-radius: 25px;

  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  backdrop-filter: brightness(0.7);

  @media screen and (min-width: 500px) {
    flex-direction: row;
    align-items: center;
    gap: 30px;
    padding: 25px 30px;
    .btn {
      margin-left: auto;
    }
  }

  ~ .config-block {
    margin-top: 20px;
    @media screen and (min-width: 500px) {
      margin-top: 30px;
    }
  }

  label {
    font-weight: bold;
    font-size: 20px;
  }

  select,
  input,
  textarea {
    font-size: 18px;
  }

  .item-list {
    width: 100%;
    ul {
      li {
        padding: 12px 0;
        ~ li {
          border-top: 1px dashed #ffffff79;
        }

        .item {
          @media screen and (min-width: 500px) {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;
          }
          .txt-container {
            flex: 1;
            @media screen and (max-width: 500px) {
              font-size: 14px;
              width: 100%;
              flex: auto;
              input {
                font-size: 14px;
              }
            }
          }
          .btn-wrap {
            display: flex;
            gap: 20px;
            width: 100%;
            justify-content: flex-end;
            margin-top: 10px;

            @media screen and (min-width: 500px) {
              width: auto;
              margin-top: 0;
            }

            .btn {
              font-size: 12px;
              padding: 10px 15px;
            }
          }
        }
      }
    }
  }
}

//================================================
// Snowflake
.snowflake-group {
  position: absolute;
  width: 8vw;
  height: 80vh;

  @media screen and (min-width: 1024px) {
    width: 14vw;
    height: 25vw;
  }

  &.left {
    left: 2vw;
    top: 5.8vw;
    .snowflake {
      &:nth-child(1) {
        top: 0;
        left: 45%;
      }
      &:nth-child(2) {
        width: 1.25vw;
        height: 1.25vw;
        top: 30%;
        left: 50%;
      }
      &:nth-child(3) {
        width: 1.75vw;
        height: 1.75vw;
        top: 39%;
        left: 10%;
      }
      &:nth-child(4) {
        width: 1.25vw;
        height: 1.25vw;
        top: 53%;
        left: 70%;
      }
      &:nth-child(5) {
        width: 2vw;
        height: 2vw;
        top: 80%;
        left: 35%;
      }
      &:nth-child(even) {
        animation-direction: reverse;
      }
    }
  }

  &.right {
    // right: 3vw;
    right: 0vw;
    top: 2vw;
    .snowflake {
      &:nth-child(1) {
        top: 0;
        left: 45%;
      }
      &:nth-child(2) {
        width: 1.5vw;
        height: 1.5vw;
        top: 30%;
        left: 50%;
      }
      &:nth-child(3) {
        width: 1.75vw;
        height: 1.75vw;
        top: 39%;
        left: 29%;
      }
      &:nth-child(4) {
        width: 1.25vw;
        height: 1.25vw;
        top: 53%;
        left: 70%;
      }
      &:nth-child(5) {
        width: 2vw;
        height: 2vw;
        top: 80%;
        left: 35%;
      }
      &:nth-child(6) {
        width: 1.4vw;
        height: 1.4vw;
      }
      &:nth-child(odd) {
        animation-direction: reverse;
      }
    }
  }

  .snowflake {
    width: 2.5vw;
    height: 2.5vw;
    background: url(./data/img/logo.png) center no-repeat;
    background-size: contain;
    position: absolute;
    transform: translate(-50%, -50%);
    animation:
      zoomIn 18s ease-in-out infinite,
      loop 20s linear infinite;
  }
}

//================================================
//XMAS CARD

.christmas-card {
  z-index: 0;
  $color-light: #f0ead2;
  $color-red: #a50c12;
  $gutter-unit: 1.5vw;
  $card-width: 70vw;
  $font-size-normal: 1.875vw;
  $font-size-large: 2.5vw;

  font-size: $font-size-normal;
  line-height: 1.2;
  height: 100%;
  text-align: center;
  border: $gutter-unit solid transparent;
  padding: $gutter-unit;
  color: $color-red;
  position: absolute;
  width: $card-width;
  height: calc($card-width * 9 / 16);
  top: 50%;
  left: 56%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  background:
    linear-gradient($color-light, $color-light) padding-box,
    repeating-linear-gradient(
        45deg,
        $color-red 0,
        $color-red 12.5%,
        $color-light 0,
        $color-light 25%,
        $color-red 0,
        $color-red 37.5%,
        $color-light 0,
        $color-light 50%
      )
      0 / calc($gutter-unit * 5) calc($gutter-unit * 5);
  * {
    font-family: "Slackside One", "cursive";
  }

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
    border: 3px solid $color-red;
    background-color: $color-light;
    background: url(./data/img/draw/card/img_card_bg.png) center center repeat;
    background-size: calc(2 * $gutter-unit);
  }

  .markers {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: $gutter-unit;
    .marker {
      width: 4.5vw;
      height: 4.5vw;
      position: absolute;
      background-size: contain;

      animation: zoomInStatic 15s ease-in-out infinite;
      &:nth-child(odd) {
        animation: zoomOutStatic 15s ease-in-out infinite;
      }
      &:nth-child(1) {
        top: 4%;
        left: 4%;
        width: 4.1vw;
        height: 4.9vw;
        background: url(./data/img/draw/card/img_card_corner_01.png) center no-repeat;
        background-size: contain;
      }
      &:nth-child(2) {
        top: 1.5%;
        right: 1%;
        width: 4.6vw;
        height: 4.061vw;
        background: url(./data/img/draw/card/img_card_corner_02.png) center no-repeat;
        background-size: contain;
      }
      &:nth-child(3) {
        bottom: 3%;
        left: 3%;
        width: 5.15625vw;
        height: 6.9792vw;
        background: url(./data/img/draw/card/img_card_corner_03.png) center no-repeat;
        background-size: contain;
      }
      &:nth-child(4) {
        right: 0.5%;
        bottom: 0%;
        width: 6.375vw;
        height: 5.073vw;
        background: url(./data/img/draw/card/img_card_corner_04.png) center no-repeat;
        background-size: contain;
      }
    }
  }

  .card-container {
    display: flex;
    gap: 2 * $gutter-unit;
    .drawing {
      user-select: none;
      pointer-events: none;
      margin-top: $gutter-unit/2;
      margin-left: $gutter-unit/2;
      height: calc(($card-width * 9 / 16) - (5 * $gutter-unit));
      width: calc(($card-width * 9 / 16) - (5 * $gutter-unit));
      transform: rotate(2.7deg);
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
      > img {
        width: 100%;
        display: block;
      }
    }
    .msg {
      flex: 1;

      &.content-align-center {
        padding-right: 1vw;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title {
        h1 {
          margin-top: $gutter-unit;
          margin-bottom: $gutter-unit;
          img {
            display: block;
            width: 92%;
          }
        }
      }

      .content {
        text-align: left;
        > label {
          font-size: $font-size-large;
          line-height: 0.8;
          &.font-vietnamese {
            font-size: $font-size-large * 0.75;
          }
          > span {
            line-height: 0.8;
            font-size: $font-size-normal;
            display: inline-block;
            padding: 0.5vw 1.25vw 1.1vw;
            background-color: #3a541a;
            color: white;
            margin-right: 1.5vw;
            line-height: 0.5;
          }
        }
        > p {
          margin-top: $gutter-unit/3;
          margin-bottom: 0;
          line-height: 1;
          display: -webkit-box;
          -webkit-line-clamp: 7;
          -webkit-box-orient: vertical;
          overflow: hidden;
          &.font-vietnamese {
            line-height: 1.4;
            font-size: $font-size-large * 0.6;
          }
        }
      }

      .content-analytics {
        label {
          background-color: #3a541a;
          color: white;
          display: inline-block;
          line-height: 0.8;
          min-width: 18vw;
          padding-left: $font-size-large/2;
          padding-right: $font-size-large/2;
          padding-bottom: 2vw;
          font-size: $font-size-large * 3;
          position: relative;
          &::before,
          &::after {
            content: "";
            display: block;
            position: absolute;
          }
          &::before {
            width: 2.70833vw;
            height: 2.70833vw;
            background: url(data/img/draw/card/img_title_corner_02.png) center no-repeat;
            background-size: contain;
            bottom: -15%;
            left: -8%;
            animation: zoomInStatic 15s ease-in-out infinite;
          }
          &::after {
            width: 4.6254vw;
            height: 7.3vw;
            background: url(data/img/draw/card/img_title_corner_01.png) center no-repeat;
            background-size: contain;
            right: -20%;
            top: -50%;
            animation: zoomOutStatic 15s ease-in-out infinite;
          }
        }
        p {
          font-size: $font-size-normal * 1.4;
          margin-top: $font-size-large/4;
          margin-bottom: 0;
        }
      }
    }
  }

  //MOBILE
  @media screen and (max-width: 1023px) {
    top: 20px;
    left: 0;
    position: relative;
    width: calc(100% - 20px);
    max-width: 350px;
    height: auto;
    padding: 25px 15px;
    border-width: 5px;
    margin: 0 auto;
    transform: none;

    .markers {
      display: none;
    }

    .card-container {
      display: block;

      .drawing {
        height: 90vw;
        width: 90vw;
        max-width: 250px;
        max-height: 250px;
        margin: 0 auto 20px;
        transform: rotate(0deg);
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
      }

      .msg {
        flex: 1;
        .title {
          h1 {
            margin: 20px 0 20px;
            img {
              width: auto;
              max-height: 80px;
              margin: 0 auto;
            }
          }
        }

        .content {
          min-height: 150px;

          > label {
            font-size: 22px;
            line-height: 1;
            > span {
              font-size: 18px;
            }
            &.font-vietnamese {
              font-size: 16px;
            }
          }
          > p {
            font-size: 18px;

            &.font-vietnamese {
              font-size: 14px;
            }
          }
        }

        .content-analytics {
          padding-top: 20px;
          label {
            line-height: 0.7;
            min-width: 150px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 20px;
            font-size: 62px;
            position: relative;
            &::before {
              width: 40px;
              height: 40px;
              bottom: -26%;
              left: -14%;
            }
            &::after {
              width: 50px;
              height: 78px;
              right: -25%;
              top: -71%;
            }
          }
          p {
            font-size: 24px;
            margin-top: 10px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.chart-container {
  width: 100%;
  overflow-x: auto; /* Bật cuộn ngang */
  padding-bottom: 20px; /* Khoảng đệm dưới cùng để hiển thị thanh cuộn */
  .chart-wrapper {
    min-width: 1000px;
    background-color: #fff;
  }
}

//HOMEPAGE
.homepage-cards {
  @media screen and (min-width: 768px) {
    display: flex;
    gap: 30px;

    > .card {
      width: 50%;
    }
  }
  .card {
    color: #ffffff;
    padding: 20px;
    backdrop-filter: brightness(0.7);
    border-radius: 27px;

    @media screen and (min-width: 768px) {
      padding: 30px 25px;
    }

    > h2 {
      text-align: center;
      margin-top: 0;
      margin-bottom: 10px;
    }

    ~ .card {
      @media screen and (max-width: 767px) {
        margin-top: 30px;
      }
    }

    > ul {
      list-style: decimal;
      padding-left: 20px;
      font-weight: bold;
      color: #fffae3;
      > li {
        padding: 10px 0;
        a {
          font-size: 18px;
          // color: #ffffff;
          @media screen and (min-width: 768px) {
            font-size: 20px;
          }
        }
      }
    }
  }
}
