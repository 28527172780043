//notification
.notification {
    color: #222;
    position: relative;
    box-shadow: none;
    opacity: 1;
    border-radius: 25px;
    background: rgba(255, 255, 255, 0.4) !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important;
    padding-top: 13px;
    padding-bottom: 12px;
    margin: 10px 0;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 25px;
        box-shadow: inset 0 0 100px rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(20px);
        z-index: -1;
    }
    .message {
        font-size: 90%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
