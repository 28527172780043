.configdrawpage {
    .form-group {
        display: flex;
        align-items: center;
        gap: 20px;
        :not(label) {
            flex: 1;
        }
        input,
        select {
            min-width: 100px;
        }
    }
    .table {
        img {
            height: auto;
        }

        td {
            font-size: 14px;
        }

        p {
            margin: 0;
            ~ p {
                margin-top: 10px;
            }
        }

        tbody {
            tr {
                td {
                    font-size: 14px;
                }
            }
        }

        a {
            user-select: none;
            cursor: pointer;
        }
        .bg-template {
            background: url(../../data/img/draw/template.jpg) center no-repeat;
            background-size: 100%;
            border: 1px solid #ccc;
            > img {
                display: block;
            }
        }
    }
}
