.moodsubmitpage {
    color: #ffffff;

    .mood-picker {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        max-width: 500px;
        margin: 0 auto;

        @media screen and (min-width: 1024px) {
            max-width: 800px;
            gap: 40px;
        }

        .mood {
            cursor: pointer;
            text-align: center;
            width: calc((100% - 30px) / 2);
            padding: 20px 8px 25px;
            border-radius: 27px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 40.63%, rgba(255, 255, 255, 0) 100%);
            backdrop-filter: blur(5px);
            position: relative;
            transition: all ease 0.35s;

            // &:before {
            //   content: "";
            //   position: absolute;
            //   z-index: 1;
            // }

            &:hover,
            &.selected {
                background: rgba(255, 255, 255, 0.5);
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
            }

            @media screen and (min-width: 1024px) {
                width: calc((100% - 120px) / 4);
            }

            img {
                width: 100px;
                display: block;
                margin: 0 auto;
            }

            p {
                font-size: 20px;
                font-weight: 700;
                margin-top: 15px;
                margin-bottom: 0;
            }
        }

        &.smaller {
            @media screen and (max-width: 768px) {
                .mood {
                    padding: 20px 10px;
                    img {
                        width: 75px;
                    }
                    p {
                        margin-top: 15px;
                        line-height: 1;
                    }
                }
            }
        }
    }

    .mood-greeting {
        padding-top: 30px;
        text-align: center;
        max-width: 500px;
        margin: 0 auto;
        @media screen and (min-width: 1024px) {
            padding-top: 40px;
        }

        .select-wrap {
            margin: 0 auto;
            max-width: 400px;
        }
    }

    .action {
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
        @media screen and (min-width: 1024px) {
            padding-top: 40px;
        }
    }

    .message-zone {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;

        .mood-message {
            padding: 20px 0;
            text-align: center;
            > img {
                width: 100%;
                max-width: 90px;
                @media screen and (min-width: 1024px) {
                    max-width: 120px;
                }
            }
            p {
                font-size: 30px;
                line-height: 1.2;
                margin-bottom: 0;
                transition: all ease-in-out 0.25s;
                @media screen and (min-width: 768px) {
                    font-size: 36px;
                }
            }
        }

        .music-box {
            width: 100%;
            padding: 30px 0px 10px;
            margin-top: auto;
            h2 {
                font-size: 18px;
                margin-top: 0;
                margin-bottom: 10px;
                @media screen and (min-width: 1024px) {
                    font-size: 22px;
                }
            }
            @media screen and (min-width: 768px) {
                padding: 40px 0 10px;
            }
        }
    }
}
