.drawanalyticspage {
    .container {
        overflow-x: hidden;
    }
    .christmas-card {
        left: 50%;

        @media screen and (max-width: 1023px) {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% - 60px);
            padding: 30px 20px 40px;
            max-width: 350px;
        }

        .markers {
            .marker {
                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }
}
