.drawgallerypage {
    .gallery {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #fff;

        border-top: 6px solid #fff;
        border-bottom: 6px solid #fff;

        @media screen and (min-width: 1024px) {
            border-top: 8px solid #fff;
            border-bottom: 8px solid #fff;
        }
    }

    .content-analytics {
        $color-light: #f0ead2;
        $color-red: #a50c12;
        $font-size-normal: 1.875vw;
        $font-size-large: 2.5vw;

        font-family: "Slackside One", "cursive";
        position: absolute;
        // top: 25%;
        // left: 25vh;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 25px 20px 20px;
        width: 100%;
        max-width: 50vh;
        // height: 25%;
        z-index: 1;

        color: $color-red;
        background-color: $color-light;
        padding: 25px 20px;
        text-align: center;
        border: 8px solid #3a541a;
        border-radius: 15px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);

        label {
            background-color: #3a541a;
            color: white;
            display: inline-block;
            line-height: 0.65;
            min-width: 14vw;
            padding-left: $font-size-large/4;
            padding-right: $font-size-large/4;
            padding-bottom: 1.5vw;
            font-size: $font-size-large * 2;
            position: relative;

            &::before,
            &::after {
                content: "";
                display: block;
                position: absolute;
            }
            &::before {
                width: 2.70833vw;
                height: 2.70833vw;
                background: url(../data/img/draw/card/img_title_corner_02.png) center no-repeat;
                background-size: contain;
                bottom: -15%;
                left: -8%;
                animation: zoomInStatic 15s ease-in-out infinite;
            }
            &::after {
                width: 4.6254vw;
                height: 7.3vw;
                background: url(../data/img/draw/card/img_title_corner_01.png) center no-repeat;
                background-size: contain;
                right: -20%;
                top: -32%;
                animation: zoomOutStatic 15s ease-in-out infinite;
            }
        }
        p {
            font-size: $font-size-normal;
            margin-top: $font-size-large/4;
            margin-bottom: 0;
        }

        @media screen and (max-width: 1023px) {
            max-width: 300px;
            border: 5px solid #3a541a;
            padding: 20px 15px 10px;

            label {
                line-height: 0.5;
                min-width: 150px;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 20px;
                font-size: 62px;
                position: relative;
                &::before {
                    width: 40px;
                    height: 40px;
                    bottom: -26%;
                    left: -14%;
                }
                &::after {
                    width: 50px;
                    height: 78px;
                    right: -25%;
                    top: -71%;
                }
            }
            p {
                font-size: 24px;
                margin-top: 5px;
                margin-bottom: 0;
            }
        }
    }
}
