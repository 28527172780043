.loginpage {
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        > section {
            width: 100%;
            max-width: 400px;
        }
        .login-form {
            border-radius: 30px;
            backdrop-filter: brightness(0.7);
            padding: 30px 25px;
            @media screen and (min-width: 768px) {
                padding: 50px 40px;
            }
            h1 {
                font-size: 40px;
                margin-top: 0;
                text-align: center;
            }
            form {
                font-size: 18px;

                @media screen and (min-width: 768px) {
                    font-size: 20px;
                }
                .input-container {
                    margin-bottom: 30px;
                    label {
                        display: inline-block;
                        margin-bottom: 10px;
                    }
                    input {
                        display: block;
                        width: 100%;
                        appearance: none;
                        padding: 15px 20px;
                        border: none;
                        border-radius: 50px;
                        background-color: #fff;
                        font-size: 16px;

                        @media screen and (min-width: 768px) {
                            padding: 20px 30px;
                            font-size: 18px;
                        }
                    }
                }
                .button-container {
                    margin-top: 40px;
                    text-align: center;
                }
            }
        }
    }
}
